<template>
  <v-data-table
    :headers="headers"
    :items="results.results"
    :options.sync="options"
    :server-items-length="results.count"
    v-if="results"
    :loading="isLoading"
    :footer-props="{itemsPerPageOptions: [5, 25, 50, 100], page: 1, sortBy: [], sortDesc: []}"
    @update:options="options = options"
  >
    <template v-slot:item.id="{ item }">
      <router-link :to="{name: `${routerNamespace}.detail`, params: {id: item.id}}">
        {{ item.id }}
      </router-link>
    </template>
    <template v-slot:item.client.name="{ item }">
      <router-link :to="{name: `communities.detail`, params: {id: item.client.id}}">
        {{ item.client.name }}
      </router-link>
    </template>
    <template v-slot:top>
      <data-table-top
        :form-title="formTitle"
        :item="editedItem"
        :entity-name="entityName"
        :entity-name-plural="entityNamePlural"
        :can-create="canCreate"
        :can-destroy="canDestroy(item)"
        :can-edit="canEdit(item)"
        :query.sync="filters.q"
        @update-query="updateQuery($event)"
        @save="save($event)"
        @destroy="destroy($event)"
        @close="close()"
        ref="top"
      >
        <template v-slot:modalForm="slotProps">
          <slot name="modalForm" :slotProps="slotProps"></slot>
        </template>
      </data-table-top>
    </template>
    <template v-slot:item.actions="{ item }" v-if="canEdit(item) || canDestroy(item)">
      <v-icon
        small
        v-if="canEdit(item)"
        class="mr-2"
        @click="editItem(item)"
      >
        {{ $icons.mdiPencil }}
      </v-icon>
      <slot name="additionalActions" :item="item"></slot>
    </template>
  </v-data-table>
</template>
<script>
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import forOwn from 'lodash/forOwn'

import http from '@/http'

import DataTableTop from './DataTableTop'

function syncFiltersAndOptions(filters, options) {
  options["itemsPerPage"] = filters["page_size"]
  options["page"] = filters["page"]
}

function toQueryString (obj) {
  let d = {}
  forOwn(obj, (val, key) => {
    if (val) {
      d[key] = val
    }
  })
  return d
}

export default {
  components: {
    DataTableTop
  },
  props: {
    headers: {type: Array},
    initialFilters: {type: Object},
    forcedFilters: {type: Object, default: () => { return {} }},
    createData: {type: Object, default: () => { return {} }},
    entityName: {type: String},
    entityNamePlural: {type: String},
    apiPath: {type: String},
    updateRoute: {type: Boolean, default: false},
    prepareItem: {},
    canEdit: {},
    canCreate: {type: Boolean, default: false},
    canDestroy: {},
    canSave: {},
    replaceEmptyWithNull: {type: Array, default: () => {return []}},
    item: {type: Object},
    itemIdField: {type: String, default: "id"},
    itemTemplate: {type: Object},
    routerNamespace: {type: String},
  },
  data () {
    let d = {
      isLoading: true,
      results: null,
      options: {},
      errors: {},
      itemErrors: {nonFieldErrors: {}, fieldErrors: {}},
      editedIndex: -1,
      editedItem: {...this.item},
      defaultItem: {...this.itemTemplate},
    }
    if (this.updateRoute) {
      d.filters = {...this.initialFilters, ...this.$route.query, ...this.forcedFilters}
    } else {
      d.filters = {...this.initialFilters, ...this.forcedFilters}
    }
    syncFiltersAndOptions(d.filters, d.options)
    return d
  },
  async created () {
    this.fetchData(this.filters)
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? `New ${this.entityName}` : `Edit ${this.entityName}`
    },
  },
  methods: {
    async fetchData(filters) {
      let response
      this.isLoading = true
      this.errors = {}
      try {
        response = await http.get(this.apiPath, {params: {...filters}})
        this.results = response.data
        if (this.prepareItem) {
          this.results.results.forEach(this.prepareItem)
        }
      } catch (e) {
        this.errors = e.backendErrors
        this.results = null
      } finally {
        this.isLoading = false
      }
    },
    editItem (item) {
      this.editedIndex = this.results.results.indexOf(item)
      this.$refs.top.editedItem = Object.assign({}, item)
      this.$refs.top.dialog = true
    },
    close () {
      this.$refs.top.dialog = false
      this.$nextTick(() => {
        this.$refs.top.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    async save (editedItem) {
      let replaceEmptyWithNull = this.replaceEmptyWithNull || []
      replaceEmptyWithNull.forEach(f => {
        if (editedItem[f] === "") {
          editedItem[f] = null
        }
      })
      if (this.editedIndex > -1) {
        await this.update(editedItem)
      } else {
        await this.create(editedItem)
      }
      if (isEqual(this.itemErrors, {nonFieldErrors: {}, fieldErrors: {}})) {
        this.close()
        await this.fetchData(this.filters)
      }
    },
    async update (item) {
      item = this.parseItemForUpdate(item)
      let response
      this.isLoading = true
      this.itemErrors = {nonFieldErrors: {}, fieldErrors: {}}
      let id = item[this.itemIdField]
      try {
        response = await http.patch(`${this.apiPath}/${id}`, item)
        this.results = response.data
      } catch (e) {
        this.itemErrors = e.backendErrors
      } finally {
        this.isLoading = false
      }
    },
    async create (item) {
      this.isLoading = true
      this.itemErrors = {nonFieldErrors: {}, fieldErrors: {}}
      try {
        await http.post(this.apiPath, {...item, ...this.createData})
        await this.fetchData(this.filters)
        this.close()
      } catch (e) {
        this.itemErrors = e.backendErrors
      } finally {
        this.isLoading = false
      }
    },
    async destroy (item) {
      this.isLoading = true
      this.itemErrors = {nonFieldErrors: {}, fieldErrors: {}}
      let id = item[this.itemIdField]
      try {
        await http.delete(`${this.apiPath}/${id}`)
        await this.fetchData(this.filters)
        this.close()
      } catch (e) {
        this.itemErrors = e.backendErrors
      } finally {
        this.isLoading = false
      }
    },
    parseItemForUpdate (item) {
      return item
    }
  },
  watch: {
    options: {
      async handler (v, o) {
        v = {page: v.page, itemsPerPage: v.itemsPerPage, sortBy: v.sortBy, sortDesc: v.sortDesc}
        o = {page: o.page, itemsPerPage: o.itemsPerPage, sortBy: o.sortBy || [], sortDesc: o.sortDesc || []}
        if (isEqual(v, o)) {
          return
        }
        this.filters.page = v.page
        this.filters.page_size = v.itemsPerPage
        if (v.sortBy.length) {
          this.filters.o = v.sortBy[0]
          if (v.sortDesc[0] === true) {
            this.filters.o = `-${this.filters.o}`
          }
        }
        if (this.updateRoute) {
          this.$router.push({query: toQueryString(this.filters)})
        }
        syncFiltersAndOptions(this.filters, this.options)
        await this.fetchData(this.filters)
      },
      deep: true,
    },
    filters: {
      handler: debounce(
        async function (v) {
          await this.fetchData(v)
        }, 250
      ),
      deep: true,
    }
  },
}
</script>